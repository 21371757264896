import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import updateTariff from "../../components/iframe/wa-oa-addon-paywall/updateTariff";
import PriceList from "../../components/iframe/wa-oa-addon-paywall/PriceList";
import "../../components/iframe/wa-oa-addon-paywall/index.scss";

const WaOaAddonPaywall = () => {
  const { getParams, getLangPath } = useQueryWaIframeParams();
  return (
    <Layout withTagManager={false} className="PgIWOAP" turnOnEventView>
      <p className="PgIWOAP__title">Pricing</p>
      <PriceList tariffs={updateTariff(getLangPath, getParams)} />
    </Layout>
  );
};

export default WaOaAddonPaywall;
