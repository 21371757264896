const updateTariff = (getLangPath, getParams) => ({
  monthly: {
    period: "Monthly",
    payTitle: "Pay $35.99",
    discount: {
      text: "month",
      transparent: true,
    },
    buyNow: {
      path: `${getLangPath}/checkout/?offerId=addon_new_monthly${getParams}`,
    },
  },
  annual: {
    period: "Annual",
    payTitle: "Pay $16.7",
    discount: {
      strikethroughText: "$431.88",
      text: "$199.99 billed annually",
    },
    buyNow: {
      path: `${getLangPath}/checkout/?offerId=addon_new_annual${getParams}`,
    },
  },
});
export default updateTariff;
