import React from "react";
import PropTypes from "prop-types";
import Tariff from "./Tariff";
import "./index.scss";

const PriceList = ({ tariffs }) => {
  const { monthly, annual } = tariffs;

  return (
    <div className="PgIWOAP-PL">
      <Tariff data={monthly} />
      <Tariff data={annual} />
    </div>
  );
};

PriceList.propTypes = {
  tariffs: PropTypes.object,
};

export default PriceList;
