import React from "react";
import { ANALYTICS_CATEGORY } from "../../index.content";
import { getTrueClasses } from "../../../../../helpers";
import TrackedLink from "../../../../Analytics/TrackedLink";
import "./index.scss";

const Tariff = ({ data }) => {
  const { period, payTitle, discount, buyNow } = data;
  return (
    <div className={getTrueClasses("PgIWOAP-PL-T", `PgIWOAP-PL-T_${period}`)}>
      <div className="PgIWOAP-PL-T__period">{period}</div>
      <div className="PgIWOAP-PL-T-block">
        <p className="PgIWOAP-PL-T__title">
          Add-on on Amazon Product Pages - Dropshipping, Arbitrage, and
          Wholesale Extension
        </p>
        <p className="PgIWOAP-PL-T__subTitle">
          Avoid selling risky products and maximize your success rate by selling
          branded and other existing products
        </p>
        <TrackedLink
          classes="PgIWOAP-PL-T__learnMore"
          category={ANALYTICS_CATEGORY}
          action="Learn more"
          path="/online-arbitrage"
          target
        >
          Learn more
        </TrackedLink>
        <div className="PgIWOAP-PL-T__line" />
        <p className="PgIWOAP-PL-T__price">
          {payTitle}
          <span>/month</span>
        </p>
        <p
          className={getTrueClasses(
            "PgIWOAP-PL-T__discount",
            discount.transparent && "PgIWOAP-PL-T__discount_transparent"
          )}
        >
          {discount.strikethroughText && (
            <strike>{discount.strikethroughText}</strike>
          )}
          {discount.text}
        </p>
        <TrackedLink
          class="PgIWOAP-PL-T__buyNow"
          category={ANALYTICS_CATEGORY}
          action="Buy now"
          path={buyNow.path}
          isChild
        >
          Buy now
        </TrackedLink>
        <p className="PgIWOAP-PL-T__refund">10-day money-back guarantee</p>
      </div>
    </div>
  );
};

export default Tariff;
